<template>
  <v-card
    id="sport-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <user-activity-tab-general
          :data-params="sportData"
          :tab="tab"
          :option="option"
          @changeTab="changeTab"
          @save="saveSport"
        ></user-activity-tab-general>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import {
  createSport,
  editSport,
  getUserActivityById,
} from '@api'

// demos
import UserActivityTabGeneral from './UserActivityTabGeneral.vue'

export default {
  components: {
    UserActivityTabGeneral,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const tab = ref(0)
    const option = ref(1)
    const sportId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const sportData = ref({})
    const postData = ref({
      name: null,
      note: null,
      playing_time: null,
      ranking_group_id: null,
      status: 'A',
    })
    const putData = ref({
      name: null,
      note: null,
      playing_time: null,
      ranking_group_id: null,
      status: 'A',
    })

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const saveSport = async data => {
      let resp = null
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          name: data.name,
          playing_time: data.playing_time,
          ranking_group_id: data.ranking_group_id,
          note: data.note,
          status: data.status,
        })

        resp = await createSport(postData.value)
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          name: data.name,
          playing_time: data.playing_time,
          ranking_group_id: data.ranking_group_id,
          note: data.note,
          status: data.status,
        })
        resp = await editSport(putData.value, data.id)
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      sportData.value = {
        name: null,
        note: null,
        playing_time: null,
        ranking_group_id: null,
        status: 'A',
      }
    }

    const goBack = () => {
      router.replace({
        name: 'views-user-activity-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      sportId.value = route.value.params.id

      if (option.value && sportId.value) {
        const resp = await getUserActivityById(sportId.value)
        if (resp.ok) {
          sportData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }
    })

    return {
      tab,
      tabs,
      option,
      sportId,
      sportData,
      postData,
      putData,
      pageData,
      filterData,

      changeTab,
      saveSport,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
