<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="computedDate"
              :label="t('transactions.date')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="computedHour"
              :label="t('fields.hour')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.user"
              :label="t('users.username')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.fullname"
              :label="t('users.fullname')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.action"
              :label="t('Action')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.facility"
              :label="t('facilities.facility')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.field"
              :label="t('fields.field')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.video"
              :label="t('videos.video')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            v-if="dataComputed.highlight"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.highlight"
              :label="t('videos.highlight')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            v-if="dataComputed.ip_address"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.ip_address"
              :label="t('videos.ip_address')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            v-if="dataComputed.meta_data"
            cols="12"
          >
            <label class="font-weight-medium">Meta Data</label>
            <pre>{{ dataComputed.meta_data }}</pre>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'

export default {

  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)

    const dataAcount = ref({})
    const initialData = {
      name: null,
      note: null,
      playing_time: null,
      status: null,
    }

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        name: null,
        note: null,
        playing_time: null,
        status: null,
      })
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const formatDate = dateString => {
      const date = new Date(dateString)

      return date.toISOString().substring(0, 10)
    }

    const fotmatHour = hour => (hour ? hour.substring(0, 5) : '')

    const computedDate = computed(() => (dataComputed.value.register_date ? formatDate(dataComputed.value.register_date) : ''))
    const computedHour = computed(() => (dataComputed.value.register_time ? fotmatHour(dataComputed.value.register_time) : ''))

    return {
      // data
      form,
      dataAcount,
      initialData,

      // computed
      dataComputed,
      statusOptions,
      computedDate,
      computedHour,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      formatDate,
      fotmatHour,

      // validations
      required,
      emailValidator,
      integerValidator,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>
